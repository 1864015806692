const Locations: any = {
    'report/new': [
        {
            title: "Zgłoś",
            path: "/report/new"
        }
    ],
    'report/status': [
        {
            title: "Status",
            path: "/report/status"
        }
    ],
    'admin': [
        {
            title: "Ustawienia administracyjne",
            path: "/"
        }
    ],
    'admin/reports': [
        {
            title: "Zgłoszenia",
            path: "/reports"
        }
    ],
    'admin/users': [
        {
            title: "Operatorzy",
            path: "/users"
        }
    ],
    'admin/users/new': [
        {
            title: "Operatorzy",
            path: "/users"
        },
        {
            title: "Dodaj",
            path: "/users/new"
        }
    ],
    'admin/profile': [
        {
            title: "Ustawienia konta",
            path: "/profile"
        }
    ]
}

export default Locations;
