import {StaticAsset} from "./StaticAsset";
import {RedactedUser} from "./User";

export interface Reports {
    id: string,
    title: string,
    shortTitle?: string
}

export interface ReportingStatus {
    id: string,
    title: string
}

export interface ReportResponse {
    id: string
}

export interface ReportRequest {
    title: string;
    description: string;
    reportingStatusId: string;
    categoryId: string;
    password: string;
    passwordConfirmation: string;
    attachments: StaticAsset[]
}

export interface ReportOperatorRequest {
    id: string;
    title: string;
    description: string;
    reportingStatusId: string;
    categoryId: string;
    password: string;
    passwordConfirmation: string;
    attachments: StaticAsset[];
    createdAt?: Date;
}

export interface Report {
    id: string;
    title: string;
    name?: string;
    description: string;
    reportingStatusId: string;
    categoryId: string;
    createdAt: Date;
    status: ReportState;
    attachments: StaticAsset[]
    messages: ReportMessage[]
}

export interface ReportMessage {
    title: string;
    description: string;
    status: ReportingStatus;
    attachments: StaticAsset[];
    createdAt: Date;
    authorType: AuthorType;
    authorId?: string;
    author?: RedactedUser;
}

export interface ReportMessageRequest {
    title: string;
    description: string;
    attachments: StaticAsset[];
    isResolution?: boolean;
}

export enum ReportState {
    New = 0,
    Read = 1,
    Answered = 2,
    Investigation = 3,
    Closed = 4,
    Resolved = 5
}

export enum AuthorType {
    User = 0,
    Operator = 1
}
