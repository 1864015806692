import {MenuProps} from "antd";

type MenuItem = Required<MenuProps>['items'][number];

export default function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
    onClick?: (e: React.MouseEvent) => void
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
        onClick
    } as MenuItem;
}
