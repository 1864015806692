import {BACKEND_BASE, GetToken} from "./LoginService";
import {ErrorMessages} from "../extensions/ErrorMessages";
import {Report, ReportingStatus, ReportMessageRequest, ReportRequest, ReportResponse, Reports} from "../types/Reports";

const ReportService = {
    getCategories: async (): Promise<Reports[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/categories`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getReportingStatuses: async (): Promise<ReportingStatus[]> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/types`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + GetToken()
                },
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status >= 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    addReport: async (request: ReportRequest): Promise<ReportResponse> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                return Promise.reject(new Error(ErrorMessages.validationFailed));
            }

            if (response.status > 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    addReportMessage: async (request: ReportMessageRequest): Promise<Report> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist/message`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request)
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 422) {
                return Promise.reject(new Error(ErrorMessages.validationFailed));
            }

            if (response.status > 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    getReport: async (): Promise<Report> => {
        try {
            const response = await fetch(`${BACKEND_BASE}/Signalist`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken(),
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 401 || response.status === 403) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status > 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            return await response.json();
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
}


export default ReportService;
