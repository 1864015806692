import {createBrowserRouter} from "react-router-dom";
import React from "react";
import NewReport from "../screens/NewReport/NewReport";
import ReportStatus from "../screens/ReportStatus/ReportStatus";
import Home from "../screens/Home/Home";
import ViewReports from "../screens/Admin/ViewReports/ViewReports";
import Users from "../screens/Admin/Users/Users";
import UserAdd from "../screens/Admin/Users/UsedAdd/UserAdd";
import UserEdit from "../screens/Admin/Users/UserEdit/UserEdit";
import ViewReport from "../screens/Admin/ViewReport/ViewReport";
import Profile from "../screens/Admin/Profile/Profile";

const Router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/report/new",
        element: <NewReport/>,
    },
    {
        path: "/report/status",
        element: <ReportStatus/>,
    },
    {
        path: "/admin",
        element: <ViewReports/>,
    },
    {
        path: "/admin/reports",
        element: <ViewReports/>,
    },
    {
        path: "/admin/reports/view/:id",
        element: <ViewReport/>,
    },
    {
        path: "/admin/users",
        element: <Users/>,
    },
    {
        path: "/admin/users/new",
        element: <UserAdd/>,
    },
    {
        path: "/admin/users/edit/:id",
        element: <UserEdit/>,
    },
    {
        path: '/admin/profile',
        element: <Profile/>
    },
    {
        path: '*',
        element: <Home/>,
    }
]);

export default Router;
