import React from 'react';
import {Modal} from "antd";
import './ReportConfirmationModal.css';
import RetoricButton from "../../../../designsystems/RetoricButton/RetoricButton";
import {CheckCircleTwoTone} from "@ant-design/icons";
import {ReportOperatorRequest} from "../../../../types/Reports";

export interface ReportConfirmationModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    payload: ReportOperatorRequest;
}

function ReportConfirmationModal({open, setOpen, payload}: ReportConfirmationModalProps) {

    const closeModal = () => {
        setOpen(false);
    }

    return (
        <Modal
            centered
            footer={[
                <RetoricButton key="back" outline className="mr-4" onClick={closeModal}>
                    Zamknij
                </RetoricButton>,
            ]}
            onCancel={closeModal}
            open={open}
            width={1000}
        >
            <div className="flex flex-col justify-center gap-4">
                <CheckCircleTwoTone className="text-5xl text-brandColor-500 mx-auto"/>
                <p className="text-2xl text-center">Raport został dodany do systemu</p>
                <p className="text-xl text-center">Osoba zgłaszająca może teraz sprawdzić status zgłoszenia korzystając z identyfikatora i hasła. Przekaż te dane w bezpieczny sposób osobie zgłaszającej.</p>
                <p className="text-xl text-center mt-6">Identyfikator zgłoszenia: {payload.id}</p>
                <p className="text-xl text-center">Hasło: {payload.password}</p>
            </div>
        </Modal>
    );
}

export default ReportConfirmationModal;
