import {Layout, Menu, MenuProps} from "antd";
import {FolderAddOutlined, FolderOpenOutlined, MenuOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";
import {useAuth} from "../../extensions/Auth";
import {Content, Header} from "antd/lib/layout/layout";
import getItem from "../../extensions/Menu";
import Logo from '../../assets/images/logo.png'
import {RouterProvider} from "react-router-dom";
import Router from "../../extensions/Routing";

export default function Main() {
    const auth = useAuth();

    const items: MenuProps['items'] = [
        getItem('Nowe zgłoszenie', '1', <FolderAddOutlined/>, undefined, undefined, () => auth.navigate('/report/new')),
        getItem('Sprawdź status', '2',
            <FolderOpenOutlined/>, undefined, undefined, () => auth.navigate('/report/status')),
        getItem('Wyloguj', '3', <UserOutlined/>, undefined, undefined, () => auth.handleLogout()),
    ];

    return (
        <div className="self-stretch flex-1">
            <Layout className={'layout flex flex-col items-stretch'}>
                <Header className="flex flex-col bg-none-important self-stretch flex-1 container lg:px-[50px]">
                    <div className={'flex flex-row justify-between'}>
                        <img src={Logo} alt={'Logo'} className="h-8 max-w-[200px] mt-4 cursor-pointer"
                             onClick={() => auth.navigate('/')}/>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            selectable={false}
                            className="flex-1 flex flex-row justify-end bg-none-important lg:max-w-[500px] max-w-[200px]"
                            overflowedIndicator={<MenuOutlined/>}
                            items={items}
                        />
                    </div>
                </Header>
                <Content className={'container lg:px-[50px] py-4 px-0'}>
                    <RouterProvider router={Router}/>
                </Content>
            </Layout>
        </div>
    )
}
