import React from 'react';
import {Modal} from "antd";
import './ConfirmationModal.css';
import Infobox from "../Infobox/Infobox";
import RetoricButton from "../RetoricButton/RetoricButton";

export interface ConfirmationModalProps {
    open: boolean;
    title?: string;
    children?: React.ReactNode,
    infoboxTitle?: string;
    setOpen: (value: boolean) => void;
    yesText?: string;
    noText?: string;
    yesAction?: () => void;
    noAction?: () => void;
}

function ConfirmationModal({
                               open,
                               title,
                               children,
                               infoboxTitle,
                               setOpen,
                               yesText,
                               noText,
                               yesAction,
                               noAction
                           }: ConfirmationModalProps) {
    return (
        <Modal
            title={title ?? 'Czy jesteś pewien?'}
            centered
            footer={[
                <RetoricButton key="back" outline className="mr-4" onClick={noAction}>
                    {noText ?? 'Nie'}
                </RetoricButton>,
                <RetoricButton key="submit" onClick={yesAction}>
                    {yesText ?? 'Tak'}
                </RetoricButton>,
            ]}
            onCancel={() => setOpen(false)}
            open={open}
            width={1000}
        >
            {children && <Infobox title={infoboxTitle ?? ''}>
                <div>
                    {children}
                </div>
            </Infobox>}
            <div className={'user-import-modal__file-import'}>

            </div>
        </Modal>
    );
}

export default ConfirmationModal;
