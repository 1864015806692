import {Layout, Menu, MenuProps} from "antd";
import {FolderAddOutlined, MenuOutlined, SettingOutlined, UsergroupAddOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";
import {useAuth} from "../../extensions/Auth";
import {Content, Header} from "antd/lib/layout/layout";
import getItem from "../../extensions/Menu";
import Logo from '../../assets/images/logo.png'
import {RouterProvider} from "react-router-dom";
import AdminRouter from "../../extensions/AdminRouting";

export default function Admin() {
    const auth = useAuth();

    const items: MenuProps['items'] = [
        getItem('Zgłoszenia', '1', <FolderAddOutlined/>, undefined, undefined, () => auth.adminNavigate('/reports')),
        getItem('Operatorzy', '2', <UsergroupAddOutlined/>, undefined, undefined, () => auth.adminNavigate('/users')),
        getItem('Ustawienia konta', '3',
            <SettingOutlined/>, undefined, undefined, () => auth.adminNavigate('/profile')),
        getItem('Wyloguj', '4', <UserOutlined/>, undefined, undefined, () => auth.handleLogout()),
    ];

    return (
        <div className="self-stretch flex-1">
            <Layout className={'layout flex flex-col items-stretch'}>
                <Header className="flex flex-col bg-none-important self-stretch flex-1 container lg:px-[50px]">
                    <div className={'flex flex-row justify-between'}>
                        <img src={Logo} alt={'Logo'} className="h-8 max-w-[200px] mt-4 cursor-pointer"
                             onClick={() => auth.adminNavigate('/')}/>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            selectable={false}
                            className="flex-1 flex flex-row justify-end bg-none-important lg:max-w-[800px] max-w-[200px]"
                            overflowedIndicator={<MenuOutlined/>}
                            items={items}
                        />
                    </div>
                </Header>
                <Content className={'container lg:px-[50px] py-4'}>
                    <RouterProvider router={AdminRouter}/>
                </Content>
            </Layout>
        </div>
    )
}
