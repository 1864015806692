import React, {ReactNode, useEffect, useState} from "react";
import {LoadingOutlined} from '@ant-design/icons';

export interface RetoricButtonProps {
    onClick?: () => void;
    outline?: boolean;
    className?: string;
    type?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
    children: ReactNode;
    size?: 'sm' | 'md' | 'lg';
    htmlType?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    upper?: boolean;
    icon?: ReactNode;
    loading?: boolean;
}

export default function RetoricButton({
                                          onClick,
                                          className,
                                          type = 'primary',
                                          children,
                                          outline,
                                          size = 'md',
                                          htmlType,
                                          upper,
                                          disabled,
                                          icon,
                                          loading
                                      }: RetoricButtonProps) {
    const [styles, setStyles] = useState('');

    useEffect(() => {
        let style = 'bg-brandColor-950 text-white hover:bg-brandColor-800';
        switch (type) {
            case 'primary':
                if (outline)
                    style = 'bg-transparent text-brandColor-950 hover:text-brandColor-800 hover:border-brandColor-800 border-2 border-brandColor-950';
                break;
            case 'secondary':
                style = 'bg-brandColor2-800 text-white hover:bg-brandColor2-700';

                if (outline)
                    style = 'bg-transparent text-slate-950 hover:text-slate-800 hover:border-brandColor2-800 border-2 border-brandColor2-800';

                setStyles(style);
                break;
            case 'tertiary':
                style = 'bg-brandColor3-800 text-white hover:bg-brandColor3-700';

                if (outline)
                    style = 'bg-transparent text-slate-950 hover:text-slate-800 hover:border-brandColor3-800 border-2 border-brandColor3-800';

                setStyles(style);
                break;
            case 'quaternary':
                style = 'bg-brandColor4-800 text-white hover:bg-brandColor4-700';

                if (outline)
                    style = 'bg-transparent text-slate-950 hover:text-slate-800 hover:border-brandColor4-700 border-2 border-brandColor4-800';

                setStyles(style);
                break;
        }

        if (disabled)
            style = 'bg-gray-300 text-gray-500 cursor-not-allowed';

        switch (size) {
            case 'sm':
                style += ` text-xs font-medium ${outline ? 'py-1.5 px-2.5' : 'py-2 px-3'}`;
                break;
            case 'md':
                style += ` text-md font-medium ${outline ? 'py-1.5 px-2.5' : 'py-2 px-3'}`;
                break;
            case 'lg':
                style += ` text-lg font-medium ${outline ? 'py-1.5 px-3.5' : 'py-2 px-4'}`;
                break;
        }

        if (upper) {
            style += ' uppercase';
        }

        style += ' tracking-wide transition duration-200 ease-in-out';

        setStyles(style);

        return () => {
        };
    }, [type, size, outline, upper, disabled, upper, loading]);

    return (
        <button onClick={onClick} className={`${styles} ${className}`} type={htmlType}>
            {!loading && <>{icon}{icon && <span className="mr-2"></span>}{children}</>}
            {loading && <LoadingOutlined style={{fontSize: 24}} spin/>}
        </button>
    )
}
