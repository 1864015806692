import RetoricBreadcrumbs from "../../../designsystems/RetoricBreadcrumbs/RetoricBreadcrumbs";
import Locations from "../../../extensions/Locations";
import React, {useEffect} from "react";
import {useAuth} from "../../../extensions/Auth";
import './Profile.css';
import {Form, Input, notification} from "antd";
import RetoricCard from "../../../designsystems/RetoricCard/RetoricCard";
import RetoricButton from "../../../designsystems/RetoricButton/RetoricButton";
import {LoginService} from "../../../services/LoginService";
import {ErrorMessages} from "../../../extensions/ErrorMessages";

export default function Profile() {
    const auth = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();

    const updatePassword = () => {
        form.validateFields().then((values) => {
            if (values.password !== values.passwordConfirmation) {
                api.error({
                    message: `Błąd`,
                    description: 'Podane hasła nie są takie same!',
                    placement: 'top'
                });
                return;
            }

            LoginService.updatePassword(values.password).then((x) => {
                api.info({
                    message: `Zaktualizowano hasło`,
                    description: `Twoje hasło zostało zmienione, możesz się teraz logować używając nowego hasła!`,
                    placement: 'top'
                });
            }).catch((e) => {
                let message = 'Nowe hasło musi być inne od poprzedniego!';

                if (e.message === ErrorMessages.nameTooShort)
                    message = `Hasło musi mieć co najmniej 12 znaków!`;

                if (e.message === ErrorMessages.userNotFound)
                    message = 'Wystąpił błąd wewnętrzny!';

                api.error({
                    message: `Błąd`,
                    description: message,
                    placement: 'top'
                });
            });
        });
    }

    useEffect(() => {
        if (!auth.authCase?.isAdmin) {
            auth.handleLogout();
            return;
        }

    }, [auth]);
    return (
        <div>
            <RetoricBreadcrumbs locations={Locations['admin/profile']} isAdmin>Ustawienia konta</RetoricBreadcrumbs>
            <RetoricCard className={'flex flex-col w-full bg-offwhite mt-4'}>
                {contextHolder}
                <Form
                    form={form}
                    onFinish={updatePassword}
                    className="flex lg:flex-row flex-col lg:items-end items-stretch lg:gap-4"
                    layout={'vertical'}>
                    <Form.Item label={"Hasło"} required name="password"
                               className="flex-1"
                               rules={[{required: true, message: 'Hasło jest wymagane!'}]}>
                        <Input.Password placeholder={'Hasło...'} className="rounded-none" size="large"/>
                    </Form.Item>
                    <Form.Item label={"Powtórz hasło"} required name="passwordConfirmation"
                               className="flex-1"
                               rules={[{required: true, message: 'Potwierdzenie hasła jest wymagane!'}]}>
                        <Input.Password placeholder={'Hasło...'} className="rounded-none" size="large"/>
                    </Form.Item>
                    <Form.Item>
                        <RetoricButton type={'primary'} htmlType={'submit'}>Zmień hasło</RetoricButton>
                    </Form.Item>
                </Form>
            </RetoricCard>
        </div>
    )
}
