import {BACKEND_BASE, GetToken} from "./LoginService";
import {ErrorMessages} from "../extensions/ErrorMessages";
import {StaticAsset} from "../types/StaticAsset";

export const StaticService = {
    addStaticImage: async (file: File): Promise<StaticAsset> => {
        try {
            const data = new FormData();
            data.append('file', file);

            const response = await fetch(`${BACKEND_BASE}/Signalist/image`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: data
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r));
            }

            const responseObject: StaticAsset = await response.json();
            responseObject.url = `${BACKEND_BASE}/Static/image/${responseObject.name}`;

            return responseObject;
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    addAttachment: async (file: File): Promise<StaticAsset> => {
        try {
            const data = new FormData();
            data.append('file', file);

            const response = await fetch(`${BACKEND_BASE}/Signalist/attachment`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': BACKEND_BASE,
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': 'Bearer ' + GetToken()
                },
                body: data
            });

            if (response.status === 401) {
                return Promise.reject(new Error(ErrorMessages.forbidden));
            }

            if (response.status === 404) {
                return Promise.reject(new Error(ErrorMessages.notFound));
            }

            if (response.status === 400) {
                return Promise.reject(new Error(ErrorMessages.badRequest));
            }

            if (response.status === 422) {
                const r = await response.json();
                return Promise.reject(new Error(r));
            }

            const responseObject: StaticAsset = await response.json();
            responseObject.url = `${BACKEND_BASE}/Static/image/${responseObject.name}`;

            return responseObject;
        } catch (e) {
            throw new Error('unauthorized');
        }
    },
    openAttachment: (name: string): void => {
        fetch(`${BACKEND_BASE}/Signalist/attachment/${name}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': BACKEND_BASE,
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': 'Bearer ' + GetToken()
            }
        })
            .then(response => response.blob())
            .then(result => {
                const file = window.URL.createObjectURL(result);
                window.open(file, '_blank');
            })
            .catch(error => console.log('error', error));
    }
}
